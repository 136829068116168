import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../components/Navbar.js";

const LoginPage =()=> {
  const navigate = useNavigate();
  const { setAuthData } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    EMail: "",
    Password: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch("https://api.ractow.com/v1/merchant-login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }); 

      const result = await response.json();
      
      if (response.ok && result.success) {
        // localStorage.setItem("authToken", result.token); // Store token for further API requests
        // localStorage.setItem("email", result.email); // Store token for further API requests
        setAuthData({ email: result.email, token: result.token });
        navigate("/device-management");
      } else {
        alert("Login failed. " + (result.message || "Invalid credentials."));
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
  <div className="d-flex flex-column align-items-center vh-100 bg-light text-center p-4">
        <Navbar />
      
      <div className="container mt-4" style={{
          width: "75vw",
          maxWidth: "600px",
          height: "60vh",
          borderRadius: "10px"
        }}>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card shadow-lg p-4">
            <h3 className="text-center mb-3">Merchant Login</h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-3 row">
                <label className="col-sm-4 col-form-label">e-Mail</label>
                <div className="col-sm-8">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-sm-4 col-form-label ">Password</label>
                <div className="col-sm-8">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="d-grid gap-2">
                <button type="submit" className="btn btn-primary" disabled={loading}>
                  {loading ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Signing in...
                      </>
                    ) : (
                      "Sign In"
                    )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
      </div>
  );
}

export default LoginPage;
