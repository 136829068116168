import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/img/ractow.png";

const Navbar = () => {
  const navigate = useNavigate();
  const { authData, signOut, isAuthentic } = useContext(AuthContext);
  
  const goHome = () => {
    navigate("/");
  }
  const handleSignOut = () => {
    signOut();
    goHome();
  };

  return (
    <nav className="navbar navbar-light bg-light w-100 p-3 justify-content align-center" style={{
          width: "60vw",
          maxWidth: "600px",
          borderRadius: "10px"
        }}>
      <img src={logo} alt="Logo" className="navbar-logo" onClick={goHome}/>

      {isAuthentic() ? (
        <div className="small">
          {authData.email}&nbsp;(
         <Link to='/device-management'>My Devices</Link> | <Link onClick={handleSignOut}>
          Sign Out
        </Link>)</div>
      ) : (
        <div className="small">Merchant: <Link to='/signup'>Sign up</Link> | <Link to='/signin'>Sign in</Link></div>
      )}
    </nav>
  );
};

export default Navbar;