import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../components/Navbar.js";

const MembershipForm = () => {
  const [formData, setFormData] = useState({
    merchantName: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    pinZip: "",
    phone: "",
    email: "",
    taxId: ""
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.MerchantName || !formData.StreetAddress || !formData.City || !formData.State || !formData.Country || !formData.PIN || !formData.EMail) {
      alert("Please fill all mandatory fields.");
      return;
    }    

    try {
      const response = await fetch("https://api.ractow.com/v1/merchant-register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData)
      });
      const result = await response.json();
      if (result.success) {
        alert("Registration successful! Check your email for login details.");
        navigate("/signin");
      } else {
        alert("Registration failed. " + result.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    
      <div className="d-flex flex-column align-items-center vh-100 bg-light text-center p-4">
        <Navbar />
      <h2 className="mb-3">Merchant Signup</h2>
      <form className="w-50" onSubmit={handleSubmit}>
        <div className="mb-3 text-start">
          <label className="form-label">Merchant Name</label>
          <input type="text" name="MerchantName" className="form-control" required onChange={handleChange} />
        </div>
        <div className="mb-3 text-start">
          <label className="form-label">Street Address</label>
          <input type="text" name="StreetAddress" className="form-control" required onChange={handleChange} />
        </div>
        <div className="mb-3 text-start d-flex gap-2">
          <input type="text" name="City" className="form-control" placeholder="City" required onChange={handleChange} />
          <input type="text" name="State" className="form-control" placeholder="State" required onChange={handleChange} />
          <input type="text" name="Country" className="form-control" placeholder="Country" required onChange={handleChange} />
          <input type="text" name="PIN" className="form-control" placeholder="PIN/ZIP Code" required onChange={handleChange} />
        </div>
        <div className="mb-3 text-start d-flex gap-2">
          <input type="email" name="EMail" className="form-control" placeholder="Email" required onChange={handleChange} />
          <input type="tel" name="Phone" className="form-control" placeholder="Phone (optional)" onChange={handleChange} />
        </div>
        <div className="mb-3 text-start">
          <label className="form-label">Tax ID (optional)</label>
          <input type="text" name="TaxID" className="form-control" onChange={handleChange} />
        </div>
        <button type="submit" className="btn btn-primary w-100">Register</button>
      </form>
    </div>
  );
};

export default MembershipForm;
