import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./App.css"; // For additional styling
import Navbar from "../components/Navbar.js";
import backgroundImage from "../assets/img/tmp.jpg";

const HomePage = () => {
 
  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light text-center">
      <Navbar />
      
      <div className="container-box" style={{
          width: "60vw",
          maxWidth: "600px",
          height: "60vh",
          background: `url(${backgroundImage}) no-repeat center center/cover`,
          borderRadius: "10px"
        }}>

      </div>
      <div className="coming-soon mt-3">
        <span className="fs-5 text-dark">Project Under Development</span>
      </div>
    </div>
  );
};

export default HomePage;