import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../components/Navbar.js"; 
import { AuthContext } from "../context/AuthContext";
import CryptoJS from "crypto-js";

const DeviceManagement = () => {
  const navigate = useNavigate();
  const { authData, isAuthentic } = useContext(AuthContext);

  const [devices, setDevices] = useState([]);
  const [deviceName, setDeviceName] = useState("");
  const [pin, setPin] = useState("");

  if (!isAuthentic()) {
      navigate("/signin");
  }
  
  useEffect(() => {
    // Fetch devices from API on load
    loadDevices();
  }, []);

  const loadDevices = () => {
  fetch("https://api.ractow.com/v1/devices", {
      headers: { "Authorization": `Bearer ${authData.token}` }
    })
      .then(res => res.json())
      .then(data => setDevices(data.devices || []))
      .catch(err => console.error("Error fetching devices:", err));
  }

  // Generate a random 4-character alphanumeric Machine ID
  const generateMachineID = () => {
    return Math.random().toString(36).substring(2, 6).toUpperCase();
  };

  // Generate an encrypted API key (for now, a random string)
  const generateMachineKey = () => {
    return btoa(Math.random().toString(36).substring(2) + Date.now());
  };

  const encryptPIN = (pin) => {
    const encrypted = CryptoJS.AES.encrypt(pin.toString(), "RACTOW-3011-AES").toString();
    return btoa(encrypted); // base64 encode
  };

  // Handle adding a device
  const handleAddDevice = async () => {

    if (deviceName.length < 3 || pin.length !== 4) {
      alert("Device Name must be at least 3 characters and PIN must be 4 digits.");
      return;
    }
    const encryptedPIN = encryptPIN(pin);

    const newDevice = {
      device_name: deviceName,
      pin: encryptedPIN,
      machine_id: generateMachineID(),
      machine_key: generateMachineKey(),
      active: true, // Auto activate
    };

    // Send to API
    const response = await fetch("https://api.ractow.com/v1/devices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authData.token}`
      },
      body: JSON.stringify(newDevice),
    });

    const result = await response.json();
    if (result.success) {
      loadDevices();
      // setDevices([...devices, newDevice]);
      setDeviceName("");
      setPin("");
    } else {
      alert("Failed to add device: " + result.message);
    }
  };

  // Handle device deletion
  const handleDeleteDevice = async (machine_id) => {
    const response = await fetch(`https://api.ractow.com/v1/devices/${machine_id}`, {
      method: "DELETE",
      headers: { "Authorization": `Bearer ${authData.token}` }
    });

    const result = await response.json();
    if (result.success) {
      // setDevices(devices.filter(device => device.machine_id !== machine_id));
      loadDevices();
    } else {
      alert("Failed to delete device.");
    }
  };


  return (
    <div className="d-flex flex-column align-items-center vh-100 bg-light text-center p-4">
        <Navbar />
      <h2>Device Management</h2>

      {/* Add Device Form */}
      <div className="card p-3 mb-3">
        <h5>Add New Device</h5>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Device Name</label>
            <input type="text" className="form-control" value={deviceName} onChange={e => setDeviceName(e.target.value)} />
          </div>
          <div className="col-md-4">
            <label className="form-label">PIN (4-digit)</label>
            <input type="password" className="form-control" maxLength="4" value={pin} onChange={e => setPin(e.target.value)} />
          </div>
          <div className="col-md-2 d-flex align-items-end">
            <button className="btn btn-primary" onClick={handleAddDevice}>Add</button>
          </div>
        </div>
      </div>

      {/* Device List */}
      {/* <div className="row"> */}
        {devices.map((device, index) => (
          <div className="col-md-4 mb-4" key={device.MacID}>
            <div className="card shadow-sm h-100">
              <div className="card-body">
                <h5 className="card-title">{device.DeviceName}</h5>
                <p className="card-text">
                  <strong>Machine ID:</strong> {device.MacID}<br />
                  <strong>Security Key:</strong> <span className="text-break">{device.SecurityKey}</span>
                </p>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDeleteDevice(device.MacID)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
{/* </div> */}

    </div>
  );
};

export default DeviceManagement;
