import { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
const [authData, setAuthData] = useState({email: null, token:null});
const signOut = () => {
  setAuthData({ email: null, token: null }); 
};

  const isAuthentic = () => {
    return authData && authData.email && authData.token
  }

  return (
    <AuthContext.Provider value={{ authData, setAuthData, signOut, isAuthentic }}>
      {children}
    </AuthContext.Provider>
  );
};
